import {
  ERichTextBullets,
  toEnum,
  toImageProps,
  toRichText,
} from '@front/shared/ds';
import React, { useMemo } from 'react';

import {
  HorizontalCardV4,
  EHorizontalCardV4ImagePosition,
  EHorizontalCardV4Theme,
} from './components';

import type { Section } from '@shared/master-types';

type TUsePreparedDataProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'horizontal-cards-v4' }
>;

type TCard = React.ComponentProps<typeof HorizontalCardV4>;

export const usePreparedData = (props: TUsePreparedDataProps) => {
  const { cards = [] } = props;

  return useMemo(
    () =>
      cards.map<TCard>(card => ({
        title: toRichText(card.title),
        subTitle: toRichText(card.subTitle),
        description: toRichText(card.description, {
          bulletsOptions: {
            bullets: ERichTextBullets.Custom,
          },
          classNames: {
            li: 'before:bg-brand-500 icon-checkmark-2 before:size-6 before:-start-[2.35rem] before:top-[0.2rem]',
            ul: 'ps-[2.5rem]',
          },
        }),
        theme: toEnum(EHorizontalCardV4Theme, card.theme),
        image: toImageProps(card.image) || undefined,
        icon: toImageProps(card.icon) || undefined,
        backgroundColor: card.backgroundColor,
        imagePosition: toEnum(
          EHorizontalCardV4ImagePosition,
          card.imagePosition,
        ),
      })),
    [cards],
  );
};
