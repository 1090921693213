import { ESimpleIconSize, Icon } from '@front/shared/ds';
import React from 'react';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';

import {
  EHorizontalCardV4ImagePosition,
  EHorizontalCardV4Theme,
  THEMES,
} from './constants';
import { Description } from './Description';

type THorizontalCardV4Props = {
  title?: React.JSX.Element | string;
  subTitle?: React.JSX.Element | string;
  description?: React.JSX.Element | string;
  image?: ImageProps;
  icon?: ImageProps;

  backgroundColor?: string;
  theme?: EHorizontalCardV4Theme;
  imagePosition?: EHorizontalCardV4ImagePosition;
};

export const HorizontalCardV4: React.FC<THorizontalCardV4Props> = ({
  title,
  subTitle,
  description,
  imagePosition = EHorizontalCardV4ImagePosition.Top,
  image,
  icon,
  theme = EHorizontalCardV4Theme.Light,
  backgroundColor,
}) => {
  const themeStyles = THEMES[theme] || THEMES[EHorizontalCardV4Theme.Light];

  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden rounded-3xl tablet:flex-row',
        themeStyles.cardBackground,
      )}
      style={backgroundColor ? { backgroundColor } : undefined}
    >
      <div className='flex flex-1 flex-col gap-6 p-6 tablet:basis-1/2 desktop:p-8'>
        {icon ? (
          <div>
            <Icon icon={icon} size={ESimpleIconSize.XxL} />
          </div>
        ) : null}

        {title ? (
          <div
            className={clsx(
              'text-4xl font-semibold leading-relaxed',
              themeStyles.titleColor,
            )}
          >
            {title}
          </div>
        ) : null}

        {subTitle ? (
          <div
            className={clsx(
              'text-xl font-semibold leading-extra-loose',
              themeStyles.subTitleColor,
            )}
          >
            {subTitle}
          </div>
        ) : null}

        <Description
          description={description}
          className={themeStyles.descriptionColor}
        />
      </div>

      <div
        className={clsx('flex flex-1 tablet:basis-1/2', {
          'items-start': imagePosition === EHorizontalCardV4ImagePosition.Top,
          'items-center':
            imagePosition === EHorizontalCardV4ImagePosition.Center,
          'items-end': imagePosition === EHorizontalCardV4ImagePosition.Bottom,
        })}
      >
        <div className='w-full'>
          {image ? <Image {...image} className='w-full' /> : null}
        </div>
      </div>
    </div>
  );
};
