export enum EHorizontalCardV4Theme {
  Light = 'Light',
  Dark = 'Dark',
}

export enum EHorizontalCardV4ImagePosition {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

type THorizontalCardV4Theme = {
  cardBackground: string;
  titleColor: string;
  subTitleColor: string;
  descriptionColor: string;
};

export const THEMES: Record<EHorizontalCardV4Theme, THorizontalCardV4Theme> = {
  [EHorizontalCardV4Theme.Light]: {
    cardBackground: 'bg-interface-50',
    titleColor: 'text-control-950',
    subTitleColor: 'text-control-950',
    descriptionColor: 'text-interface-800 prose-li:text-control-950',
  },
  [EHorizontalCardV4Theme.Dark]: {
    cardBackground: 'bg-interface-1000',
    titleColor: 'text-interface-50',
    subTitleColor: 'text-interface-50',
    descriptionColor: 'text-interface-500 prose-li:text-interface-50',
  },
};
