import { Container, EContainerVariant } from '@front/shared/ds';
import React from 'react';

import { HorizontalCardV4 } from './components';
import { usePreparedData } from './usePreparedData';

import type { Section } from '@shared/master-types';

export type THorizontalCardsV4ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'horizontal-cards-v4' }
>;

const HorizontalCardsV4Connected: React.FC<
  THorizontalCardsV4ConnectedProps
> = props => {
  const preparedCards = usePreparedData(props);

  return (
    <Container
      variant={EContainerVariant.Full}
      className='max-w-[462px] tablet:max-w-full'
    >
      <div className='flex flex-col gap-4'>
        {preparedCards.map((card, index) => (
          <HorizontalCardV4 key={index} {...card} />
        ))}
      </div>
    </Container>
  );
};

export default HorizontalCardsV4Connected;
